import http from "../http"

export default {
    //获取图文验证码
    getImageCode(param) {
        return http.get("/auth/login/getImageCode", param)
    },
    //密码登录
    authLogin(param) {
        return http.post("/auth/login/password", param)
    },
    // 获取短信验证码
    getSmsCode(phone) {
        return http.post(`/sendSmsCode/${phone}`)
    },
    //短信验证码登录
    mobileLogin(param) {
        return http.post("/auth/login/sms", param)
    },
    //获取菜单树
    getMenuTree(param) {
        return http.get("/auth/menus", param)
    },
    //登出
    authLogout(param) {
        return http.post("/auth/logout", param)
    },
    /**
     * 获取个人信息
     * @param {*} param 
     * @returns 
     */
    getUserProfile(param) {
        return http.get("/user/profile", param);
    },

}