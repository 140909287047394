import http from "../http"
export default {
  /**
   * 设备统计数据
   * @param {*} param 
   * @returns 
   */
  getCountTotalDevice(param) {
    return http.get('/device/countTotalDevice', param)
  },
  /**
   * 获取设备列表 - 分页
   * @param {*} param 
   * @returns 
   */
  getDeviceList(param) {
    return http.get("/device/getDeviceList", param)
  },
  /**
   * 创建设备
   * @param {*} param 
   * @returns 
   */
  addDevice(param) {
    return http.post('/device/addDevice', param)
  },
  /**
   * 批量删除设备
   * @param {*} param 
   * @returns 
   */
  batchDelDevice(param) {
    return http.delete("/api/device/batchDelDevice", param);
  },
  /**
   * 按照产品导出设备
   * @param {*} pk 
   * @returns 
   */
  exportDeviceByPk(pk) {
    return http.get("/device/exportDevice/" + pk)
  },
  /**
   * 根据pk、devId 启用设备
   * @param {*} pk 
   * @param {*} devId 
   * @returns 
   */
  enableDevice(pk, devId) {
    return http.post(`/device/enableDevice/${pk}/${devId}`)
  },
  /**
   * 根据pk、devId 禁用设备
   * @param {*} pk 
   * @param {*} devId 
   * @returns 
   */
  disableDevice(pk, devId) {
    return http.post(`/device/disableDevice/${pk}/${devId}`)
  },
  /**
   * 根据pk、devId 获取设备基本信息
   * @param {*} pk 
   * @param {*} devId 
   * @returns 
   */
  getDeviceInfo(pk, devId) {
    return http.get(`/device/deviceInfo/${pk}/${devId}`)
  },
  /**
   * 更新设备名称
   * @param {*} param 
   * @returns 
   */
  updateDeviceName(param) {
    return http.put("/api/device/updateName", param);
  },
  /**
   * 根据pk、devId获取设备影子
   * @param {*} pk 
   * @param {*} devId 
   * @returns 
   */
  getSnapshot(param) {
    return http.get(`/device/getSnapshot`, param)
  },
  /**
   * 批量添加设备 - 文件上传
   * @param {*} pk 
   * @param {*} param 
   * @returns 
   */
  batchAddDeviceByFile(pk, param) {
    return http.upLoadFile("/device/batchAddDeviceByFile/" + pk, param)
  },
  /**
   * 批量添加设备
   * @param {*} param 
   * @returns 
   */
  batchAddDevice(param) {
    return http.post("/device/batchAddDevice", param)
  },
  /**
   * 获取所有类型指令
   * @returns 
   */
  getAllActions() {
    return http.get("/action")
  },
  /**
   * 根据pk、devId获取上下行数据
   * @param {*} pk 
   * @param {*} devId 
   * @returns 
   */
  getDevicePackage(pk, devId, param) {
    return http.get(`/device/getDevicePackage/${pk}/${devId}`, param)
  },
  /**
   * 根据pk、devId获取子设备列表
   * @param {*} param 
   * @returns 
   */
  getDevicetTopo(param) {
    return http.get("/device/topo", param)
  },
  /**
   * 批量添加子设备
   * @param {*} pk 
   * @param {*} param 
   * @returns 
   */
  batchAddDeviceTopoByFile(pk, devId, body, param) {
    return http.batchFilePut(`/device/batchAddTopo/${pk}/${devId}`, body, param)
  },
  /**
   * 解绑子设备
   * @param {*} pk 
   * @param {*} devId 
   * @returns 
   */
  delDeviceTopo(pk, devId, body) {
    return http.delete(`/device/delTopo/${pk}/${devId}`, body)
  },
  /**
   * 根据pk获取设备
   * @param {*} pk 
   * @returns 
   */
  getDeviceByPk(pk) {
    return http.get(`/device/list/${pk}`)
  },
  /**
   * 添加子设备
   * @param {*} pk 
   * @param {*} devId 
   * @returns 
   */
  deviceAddTopo(pk, devId, body) {
    return http.put(`/device/addTopo/${pk}/${devId}`, body)
  },
  /**
   * 指标趋势
   * @param {*} pk 
   * @param {*} devId 
   * @param {*} params 
   * @returns 
   */
  getDeviceKvlog(pk, devId, params) {
    return http.get(`/stat/device/kvlog/${pk}/${devId}`, params)
  },
  /**
   * 模拟下发
   * @param {*} pk 
   * @param {*} devId 
   * @param {*} param 
   * @returns 
   */
  deviceClondSend(pk, devId, param) {
    return http.post(`/api/device/cloudSend/${pk}/${devId}`, param)
  },
  /**
   * 根据pk获取产品的远程配置
   * @param {*} pk 
   * @param {*} param 
   * @returns 
   */
  getProductConfig(pk, param) {
    return http.get(`/config/${pk}`, param)
  },
  /**
   * 根据pk获取远程配置历史版本
   * @param {*} pk 
   * @param {*} param 
   * @returns 
   */
  getHistoryConfig(pk, param) {
    return http.get(`/config/history/${pk}`, param)
  },
  /**
   * 保存并启用远程配置信息
   * @param {*} param 
   * @returns 
   */
  saveAndEnableConfig(param) {
    return http.put("/config/saveAndEnable", param)
  },
  /**
   * 禁用配置文件
   * @param {*} param 
   * @returns 
   */
  disableConfig(param) {
    return http.put('/config/disable', param);
  },
  /**
   * 获取升级列表
   * @param {*} param 
   * @returns 
   */
  getUpgradeList(param) {
    return http.get(`/device/upgrade`, param)
  },
  /**
   * 上传升级包文件
   * @param {*} param 
   * @param {*} cb 
   * @returns 
   */
  upgradeAddFile(param, cb) {
    return http.upLoadFile("/device/upgrade/uploadFile", param, cb)
  },
  /**
   * 新增升级包
   * @param {*} param 
   * @returns 
   */
  createUpgrade(param) {
    return http.post(`/device/upgrade`, param)
  },
  /**
   * 删除升级包
   * @param {*} id 
   * @returns 
   */
  delUpgrade(id){
    return http.delete(`/device/upgrade/${id}`)
  },
  /**
   * 启用升级包
   * @param {*} id 
   * @returns 
   */
  enableUpgrade(id){
    return http.post(`/device/upgrade/enable/${id}`)
  },
  /**
   * 禁用升级包
   * @param {*} id 
   * @returns 
   */
  disableUpgrade(id){
    return http.post(`/device/upgrade/disable/${id}`)
  }
}