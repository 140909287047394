/*
 * @Description: 任务管理 - apis
 * @Author: kecraft
 * @Date: 2024-02-28 09:39:46
 * @LastEditors: kecraft
 * @LastEditTime: 2024-02-28 18:33:52
 * @FilePath: /impact-iotos-console/src/api/modules/task.js
 */
import http from "../http"
export default {
  /**
   * 获取全部的任务
   * @param {*} param 
   * @returns 
   */
  getAllTaskGroups(param) {
    return http.get('/admin/drivers/tasks/taskGroups', param)
  },
  /**
   * 根据驱动Id创建新任务
   * @param {*} driverId 
   * @param {*} param 
   * @returns 
   */
  createNewTask(driverId, param) {
    return http.post(`/admin/drivers/tasks/newTaskByDriverId/${driverId}`, param)
  },
  /**
   * 根据id删除任务
   * @param {*} id 
   * @returns 
   */
  taskDelectById(id) {
    return http.delete(`/admin/drivers/tasks/delete/${id}`)
  },
  /**
   * 重启任务
   * @param {*} id 
   * @returns 
   */
  restartTask(id) {
    return http.put(`/admin/drivers/tasks/restart/${id}`)
  },
  /**
   * 启动任务
   * @param {*} id 
   * @returns 
   */
  startTask(id) {
    return http.put(`/admin/drivers/tasks/start/${id}`)
  },
  /**
   * 停止任务
   * @param {*} id 
   * @returns 
   */
  stopTask(id) {
    return http.put(`/admin/drivers/tasks/stop/${id}`)
  },
  /**
   * 修改任务信息
   * @param {*} taskId 
   * @param {*} param 
   * @returns 
   */
  editDriversTask(taskId, param) {
    return http.put(`/admin/drivers/tasks/${taskId}`, param)
  },
  /**
   * 根据id获取task的信息
   * @param {*} id 
   * @returns 
   */
  getTaskInfoById(id) {
    return http.get(`/admin/drivers/tasks/detail/${id}`);
  },
  /**
   * 获取任务的历史日志
   * @param {*} id 
   * @returns 
   */
  getHistoryTaskLog(id) {
    return http.get(`/admin/drivers/tasks/log/last/${id}`)
  }
}