/*
 * @Description: 规则引擎 - api
 * @Author: kecraft
 * @Date: 2024-03-07 18:02:03
 * @LastEditors: kecraft
 * @LastEditTime: 2024-06-11 16:31:03
 * @FilePath: /impact-iotos-console/src/api/modules/rules.js
 */
import http from "../http"

export default {
  /**
   * 分页获取rule
   * @param {*} param 
   * @returns 
   */
  getRulesByPage(param) {
    return http.get("/rule", param)
  },
  /**
   * 获取数据转发操作类型列表
   * @param {*} param 
   * @returns 
   */
  getRuleTaskType(param) {
    return http.get(`/rule/task/type`, param)
  },
  /**
   * 创建规则
   * @param {*} param 
   * @returns 
   */
  createRules(param) {
    return http.post("/rule", param)
  },
  /**
   * 删除规则
   * @param {*} ruleId 
   * @returns 
   */
  delRules(ruleId) {
    return http.delete(`/rule/${ruleId}`)
  },
  /**
   * 启用禁用规则
   * @param {*} ruleId 
   * @param {*} enable 
   * @returns 
   */
  enableRule(ruleId, enable) {
    return http.put(`/rule/${ruleId}/${enable}`)
  },
  /**
   * 根据ruleId获取规则信息
   * @param {*} ruleId 
   * @returns 
   */
  getRuleInfoByRuleId(ruleId) {
    return http.get(`/rule/${ruleId}`)
  },
  /**
   * 更新规则
   * @param {*} ruleId 
   * @param {*} param 
   * @returns 
   */
  editRules(ruleId, param) {
    return http.put(`/rule/${ruleId}`, param)
  },
  /**
   * 获取历史记录
   * @param {*} ruleId 
   * @param {*} param 
   * @returns 
   */
  getRulesHistoryByRuleId(ruleId, param) {
    return http.get(`ruleMessage/list/${ruleId}`, param)
  },
  /**
   * 重试
   * @param {*} id 
   * @returns 
   */
  ruleMessageRetry(id) {
    return http.post(`/ruleMessage/retry/${id}`)
  }
}