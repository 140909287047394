/*
 * @Description: 
 * @Author: kecraft
 * @Date: 2024-02-27 14:26:17
 * @LastEditors: kecraft
 * @LastEditTime: 2024-02-29 10:48:24
 * @FilePath: /impact-iotos-console/src/api/modules/drive.js
 */
import http from "../http"
export default {
  /**
   * 获取驱动列表 - 分页
   * @param {*} param 
   * @returns 
   */
  getDriversByPage(param) {
    return http.get('/admin/drivers/page', param)
  },
  /**
   * 根据驱动Id删除驱动
   * @param {*} id 
   * @returns 
   */
  delDriverById(id) {
    return http.delete(`/admin/drivers/${id}`)
  },
  /**
   * 驱动包上传
   * @param {*} param 
   * @returns 
   */
  driverAddFile(param, cb) {
    return http.upLoadFile("/admin/drivers/addFile", param, cb)
  },
  /**
   * 创建驱动
   * @param {*} param 
   * @returns 
   */
  createDriver(param) {
    return http.post("/admin/drivers", param)
  },
  /**
   * 根据驱动导入产品
   * @param {*} id 
   * @returns 
   */
  importPorductsFromDriver(id) {
    return http.put(`/admin/drivers/importProductsFromDriver/${id}`)
  },
  /**
   * 根据驱动id获取驱动信息
   * @param {*} id 
   * @returns 
   */
  getDriversById(id) {
    return http.get(`/admin/drivers/${id}`)
  },
  /**
   * 编辑驱动
   * @param {*} id 
   * @param {*} param 
   * @returns 
   */
  editDrivers(id, param) {
    return http.put(`/admin/drivers/${id}`, param)
  }
}