import axios from 'axios'
import { javaEndPoint } from '@/utils/config'
import { ElMessage } from 'element-plus'
const baseUrl = javaEndPoint
const service = axios.create({ baseURL: baseUrl })
import router from "../router/router"

axios.interceptors.response.use(response => Promise.resolve(response),
    error => {
        console.log(error)
    }
)

class Http {
    defaultHeader() {
        const header = {
            Accept: '*/*',
            'Content-Type': 'application/json'
        }
        return header
    }

    // URL构建方法
    build(url, params) {
        const ps = []
        if (params) {
            for (const p in params) {
                if (p) {
                    ps.push(p + '=' + encodeURIComponent(params[p]))
                }
            }
        }
        const arr = Object.keys(params)
        if (arr.length === 0) {
            return url
        }
        return url + '?' + ps.join('&')
    }
    request(url, options, responseType = "json", callback, timeout) {
        options.headers = this.defaultHeader()
        const token_url = process.env.VUE_APP_TOKEN
        const tokenstr = localStorage.getItem(token_url) || ""
        return service({
            responseType: responseType,
            method: options.method,
            url: url,
            data: options.body,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: tokenstr
            },
            timeout: timeout || 50000,
            onUploadProgress: function (progressEvent) {
                if (callback) {
                    callback(progressEvent)
                }
            },

        }).then(res => {
            return Promise.resolve({ code: '0', res })
        }).catch((error) => {
            // message, config, code, request, response
            if (error.response) {
                const code = error.response.status
                if (code === 401) {
                    //判断是否是登录操作
                    let isLogin = url.indexOf("/auth/login")
                    if (isLogin != -1) {
                        //如果是登录操作 给出错误提示
                        ElMessage.error(error.response.data.data)
                        const data = error.response.data
                        return Promise.resolve({ code: '1', data })
                    } else {
                        router.push("/login")
                        //如果不是登录操作 返回登录页面
                    }
                } else if (code === 404) {
                    //跳转404
                } else if (error.response.data) {
                    if (error.response.data && error.response.data.length > 0) {
                        let arr = error.response.data.data
                        let str = error.response.data.msg + "!" + " <br/>"
                        arr.forEach((item, index) => {
                            str += (index + 1) + '.' + item.cause + " <br/>"
                        })
                        ElMessage.error({
                            dangerouslyUseHTMLString: true,
                            message: str,
                        })
                    } else {
                        ElMessage.error(error.response.data.msg)
                    }
                    const data = error.response.data
                    return Promise.resolve({ code: '1', data })
                }
            }
        })
    }

    downloadrequest(url, options, responseType = "json", callback) {
        options.headers = this.defaultHeader()
        const token_url = process.env.VUE_APP_TOKEN
        const tokenstr = localStorage.getItem(token_url) || ""
        return service({
            responseType: responseType,
            method: options.method,
            url: url,
            data: options.body,
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                Authorization: tokenstr
            },
            timeout: 50000,
            onDownloadProgress: function (progressEvent) {
                if (callback) {
                    callback(progressEvent)
                }
            },
        }).then(res => {
            return Promise.resolve({ code: '0', res })
        }).catch((error) => {
            // message, config, code, request, response
            console.log(error.response);
            if (error.response) {
                const code = error.response.status
                if (code === 401) {
                    //判断是否是登录操作
                    let isLogin = url.indexOf("/auth/login")
                    if (isLogin != -1) {
                        //如果是登录操作 给出错误提示
                        ElMessage.error(error.response.data.data)
                        const data = error.response.data
                        return Promise.resolve({ code: '1', data })
                    } else {
                        router.push("/login")
                        //如果不是登录操作 返回登录页面
                    }
                } else if (code === 404) {
                    //跳转404
                } else if (error.response.data) {
                    ElMessage.error(error.response.data.msg)
                    const data = error.response.data.data
                    return Promise.resolve({ code: '1', data })
                }
            }
        })
    }

    request1(url, body) {
        const token_url = process.env.VUE_APP_TOKEN
        const tokenstr = localStorage.getItem(token_url) || ""
        return service({
            method: "POST",
            url: url,
            data: body,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: tokenstr
            },
            timeout: 50000
        }).then(res => {
            return Promise.resolve({ code: '0', res })
        }).catch((error) => {
            ElMessage.error(error.response.data.msg)
            return Promise.resolve({ code: '1' })
        })
    }

    requestPut(url, body) {
        const token_url = process.env.VUE_APP_TOKEN
        const tokenstr = localStorage.getItem(token_url) || ""
        return service({
            method: "PUT",
            url: url,
            data: body,
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: tokenstr
            },
            timeout: 50000
        }).then(res => {
            return Promise.resolve({ code: '0', res })
        }).catch((error) => {
            if (error.response) {
                const code = error.response.status
                if (code === 401) {
                    //判断是否是登录操作
                    let isLogin = url.indexOf("/auth/login")
                    if (isLogin != -1) {
                        //如果是登录操作 给出错误提示
                        ElMessage.error(error.response.data.data)
                        const data = error.response.data
                        return Promise.resolve({ code: '1', data })
                    } else {
                        router.push("/login")
                        //如果不是登录操作 返回登录页面
                    }
                } else if (code === 404) {
                    //跳转404
                } else if (error.response.data) {
                    ElMessage.error(error.response.data.msg)
                    const data = error.response.data.data
                    return Promise.resolve({ code: '1', data })
                }
            }
        })
    }
    /*
        GET请求
        参数1：url - 请求路径
        参数2：params - url类型的参数
    */
    get(url, params, params2) {
        let newUrl = ""
        // newUrl = params ? url + this.build(newUrl, params) : url
        // newUrl = params2 ? newUrl + "/" + params2 : newUrl
        newUrl = params2 ? url + "/" + params2 : url
        newUrl = params ? newUrl + this.build("", params) : newUrl
        return this.request(newUrl, { method: 'GET' })
    }

    /*
        POST请求
        参数1：url - 请求路径
        参数2：body - body体参数
        参数3：params - url类型的参数
    */
    post(url, body, params) {
        const options = {
            method: 'POST'
        }
        if (body) {
            options.body = JSON.stringify(body)
        }
        url = params ? url + '/' + params : url
        return this.request(url, options)
    }
    authpost(url, params) {
        const options = {
            method: 'POST'
        }
        url = params ? url + this.build("", params) : url
        return this.request(url, options)
    }
    /*
        PUT请求
        参数1：url - 请求路径
        参数2：body - body体参数
        参数3：params - url类型的参数
    */
    put(url, body, params) {
        const options = {
            method: 'PUT'
        }
        url = params ? url + '/' + params : url
        if (body) options.body = JSON.stringify(body)
        return this.request(url, options)
    }


    /*
        DELETE请求
        参数1：url - 请求路径
        参数2：params - url类型的参数
    */
    delete(url, body, params) {
        const options = {
            method: 'DELETE'
        }
        url = params ? url + '/' + params : url
        if (body) options.body = JSON.stringify(body)
        return this.request(url, options)
    }

    downLoadBlob(url, params) {
        let newUrl = ""
        newUrl = params ? url + this.build(newUrl, params) : url
        return this.request(newUrl, { method: 'GET' }, "blob")
    }

    downLoadBlobCallback(url, params, callback) {
        let newUrl = ""
        newUrl = params ? url + this.build(newUrl, params) : url
        return this.downloadrequest(newUrl, { method: 'GET' }, "blob", callback)
    }

    // 批量导入
    batchFile(url, body, params) {
        let newUrl = ""
        newUrl = params ? url + this.build(newUrl, params) : url
        return this.request1(newUrl, body)
    }

    batchFilePut(url, body, params) {
        let newUrl = ""
        newUrl = params ? url + this.build(newUrl, params) : url
        return this.requestPut(newUrl, body)
    }
    //文件上传
    upLoadFile(url, body, callback) {
        const options = {
            method: 'POST'
        }
        options.body = body
        return this.request(url, options, null, callback, 6000000)
    }

}

export default new Http()