/*
 * @Description: 产品管理-api
 * @Author: kecraft
 * @Date: 2024-01-04 15:11:23
 * @LastEditors: kecraft
 * @LastEditTime: 2024-03-06 14:31:06
 * @FilePath: /impact-iotos-console/src/api/modules/product.js
 */
import http from "../http"
export default {
  /**
   * 获取所有产品列表
   * @param {*} param 
   * @returns 
   */
  getAllProducts(param) {
    return http.get("/product", param);
  },
  /**
   * 产品新增
   * @param {*} param 
   * @returns 
   */
  productAdd(param) {
    return http.post('/product', param)
  },
  /**
   * 产品列表分页
   * @param {*} param 
   * @returns 
   */
  productPage(param) {
    return http.get("/product/page", param)
  },
  /**
   * 删除产品根据pk
   * @param {*} pk
   * @returns 
   */
  productDel(pk) {
    return http.delete("/product/" + pk, {});
  },
  /**
   * 根据产品pk获取产品详情
   * @param {*} pk 
   * @returns 
   */
  getProductInfoByPK(pk) {
    return http.get("/product/" + pk)
  },
  /**
   * 根据pk修改产品信息
   * @param {*} param 
   * @param {*} pk 
   * @returns 
   */
  productEdit(param, pk) {
    return http.put("/product/" + pk, param)
  },
  /**
   * 产品详情-产品概览
   * @param {*} pk 
   * @returns 
   */
  getProductDetailsByPk(pk) {
    return http.get("/product/detail/" + pk)
  },
  /**
   * 产品完整定义-导出
   * @param {*} pk 
   * @returns 
   */
  getProductDefinition(pk) {
    return http.get("/product/definition/" + pk)
  },
  /**
   * 根据pk获取功能定义
   * @param {*} pk 
   * @returns 
   */
  protocolModel(pk) {
    return http.get("/model/protocol/" + pk)
  },
  /**
   * 新增参数
   * @param {*} pk 
   * @param {*} param 
   * @returns 
   */
  protocolParamAdd(pk, param) {
    return http.post(`/model/protocol/${pk}/param`, param)
  },
  /**
   * 编辑参数
   * @param {*} pk 
   * @param {*} param 
   * @returns 
   */
  protocolParamEdit(pk, param) {
    return http.put(`/model/protocol/${pk}/param`, param)
  },
  /**
   * 删除自定义参数
   * @param {*} pk 
   * @param {*} cmd 
   * @returns 
   */
  protocolParamDel(pk, param) {
    return http.delete(`/model/protocol/${pk}/param/${param}`);
  },
  /**
   * 删除命令
   * @param {*} pk 
   * @param {*} param 
   * @returns 
   */
  protocolCmdDel(pk, cmd) {
    return http.delete(`/model/protocol/${pk}/cmd/${cmd}`);
  },
  /**
   * 新增命令
   * @param {*} pk 
   * @param {*} param 
   * @returns 
   */
  protocolCmdAdd(pk, param) {
    return http.post(`/model/protocol/${pk}/cmd`, param)
  },
  /**
   * 编辑命令
   * @param {*} pk 
   * @param {*} param 
   * @returns 
   */
  protocolCmdEdit(pk, param) {
    return http.put(`/model/protocol/${pk}/cmd`, param)
  },
  /**
   * 获取全部产品品类
   * @param {*} param 
   * @returns 
   */
  getAllCategory(param) {
    return http.get("/category", param)
  },
  /**
   * 导入标准功能定义
   * @param {*} pk 
   * @returns 
   */
  protocolImport(pk) {
    return http.post("/model/protocol/import/" + pk)
  },
  /**
   * 导入产品
   * @param {*} pk 
   * @returns 
   */
  productImport(param) {
    return http.post("/product/import", param)
  },
  /**
   * 导入功能定义
   * @param {*} pk 
   * @returns 
   */
  protocolImportAdd(pk, param) {
    return http.post("/model/protocol/" + pk, param)
  },
  /**
   * 根据pk获取产品的数据脚本
   * @param {*} pk 
   * @returns 
   */
  getDataShapeScript(pk) {
    return http.get("/dataShapeScript/" + pk)
  },
  /**
   * 根据pk修改产品的数据脚本
   * @param {*} pk 
   * @param {*} param 
   * @returns 
   */
  dataShapeScriptChange(pk, param) {
    return http.put("/dataShapeScript/" + pk, param)
  },
  /**
   * 批量删除命令
   * @param {*} pk 
   * @param {*} param 
   * @returns 
   */
  batchDelCmds(pk, param) {
    return http.delete(`/model/protocol/${pk}/cmd/batch`, param)
  },
  /**
   * 批量删除参数
   * @param {*} pk 
   * @param {*} param 
   * @returns 
   */
  batchDelParam(pk, param) {
    return http.delete(`/model/protocol/${pk}/param/batch`, param)
  },
  /**
   * 分页获取数据订阅列表
   * @param {*} param 
   * @returns 
   */
  pageGetDataSub(param) {
    return http.get(`/datasub`, param)
  },
  /**
   * 新建数据订阅
   * @param {*} param 
   * @returns 
   */
  createDataSub(param) {
    return http.post(`/datasub`, param)
  },
  /**
   * 启用禁用数据订阅
   * @param {*} ruleId 
   * @param {*} enable 
   * @returns 
   */
  ruleIdEnable(ruleId, enable) {
    return http.put(`/datasub/${ruleId}/${enable}`)
  },
  /**
   * 删除数据订阅
   * @param {*} ruleId 
   * @returns 
   */
  ruleDelete(ruleId) {
    return http.delete(`/datasub/${ruleId}`)
  },
  /**
   * 修改数据订阅
   * @param {*} ruleId 
   * @param {*} param 
   * @returns 
   */
  dataSubEdit(ruleId, param) {
    return http.put(`/datasub/${ruleId}`, param)
  },
  /**
   * 获取产品的script内容
   * @param {*} pk 
   * @returns 
   */
  productScript(pk) {
    return http.get(`/product/script/${pk}`)
  },
  /**
   * 正式保存script
   * @param {*} pk 
   * @param {*} param 
   * @returns 
   */
  productScriptSave(pk, param) {
    return http.post(`/product/script/${pk}/formal`, param)
  },
  /**
   * 保存草稿script
   * @param {*} pk 
   * @param {*} param 
   * @returns 
   */
  productDraftScriptSave(pk, param) {
    return http.post(`/product/script/${pk}/draft`, param)
  },
  /**
   * 删除草稿
   * @param {*} pk 
   * @returns 
   */
  draftScriptDelete(pk) {
    return http.delete(`/product/script/${pk}/draft`)
  }
}