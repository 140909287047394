/*
 * @Description: 
 * @Author: kecraft
 * @Date: 2023-07-24 09:22:14
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-11 14:12:45
 * @FilePath: /impact-iotos-console/src/api/api.js
 */
import test from "./modules/test"
import auth from './modules/auth.js'
import user from './modules/user.js'
import product from "./modules/product.js"
import device from "./modules/device.js"
import category from "./modules/category.js"
import drive from "./modules/drive.js"
import task from "./modules/task.js"
import rules from "./modules/rules.js"
import dashboard from "./modules/dashboard.js"

export default {
  ...test,
  ...auth,
  ...user,
  ...product,
  ...device,
  ...category,
  ...drive,
  ...task,
  ...rules,
  ...dashboard
}