/*
 * @Description: 看板 - api
 * @Author: kecraft
 * @Date: 2024-05-11 14:11:45
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-11 15:16:21
 * @FilePath: /impact-iotos-console/src/api/modules/dashboard.js
 */
import http from "../http"
export default {
  /**
   * 设备数、产品数
   * @param {*} param 
   * @returns 
   */
  countTotal(param) {
    return http.get('/dashboard/countTotal', param)
  },
  /**
   * 设备类型数量统计
   * @param {*} param 
   * @returns 
   */
  countDeviceByDeviceType(param) {
    return http.get(`/dashboard/countDeviceByDeviceType`, param)
  },
  /**
   * 设备指标趋势
   * @param {*} param 
   * @returns 
   */
  deviceStatDashboard(param) {
    return http.get(`/stat/device/dashboard`, param)
  },
  /**
   * 设备消息量数量统计
   * @param {*} param 
   * @returns 
   */
  countPackageNumber(param){
    return http.get(`/dashboard/countPackageNumber`,param)
  }
}