import http from "../http"

export default {
    // 新增用户
    addUser(param) {
        return http.post('/user', param)
    },
    // 用户列表
    userList(param) {
        return http.get('/user/list', param)
    },
    // 启用禁用
    modifyEnable(param) {
        return http.post('/user/modifyEnable', param)
    },
    // 编辑用户
    userEdit(param, id) {
        return http.post('/user', param, id)
    },
    // 获取用户信息
    getInfoById(id) {
        return http.get("/user/" + id, {})
    },
    // userDel(param, id) {
    //     return http.delete('/user', param, id)
    // },
    // 单个删除用户
    userRemoveById(param) {
        return http.get("/user/removeById", param)
    },
    // 批量删除用户
    userDelete(param) {
        return http.delete('/user/batch_delete', param)
    },
    // 组织列表
    organizationList(param) {
        return http.get('/organization/tree', param)
    },
    // 新增组织
    orgCreate(param) {
        return http.post("/organization", param)
    },
    // 项目经理列表
    getListByType(param) {
        return http.post('/user/getListByType', param)
    },
    // 编辑组织
    organizationEdit(id, param) {
        return http.put('/organization/' + id, param)
    },
    // 删除组织
    orgDel(param, id) {
        return http.delete('/organization', param, id)
    },
    // 重置密码为123456
    resetPassword(param) {
        return http.get('/user/reset/password', param)
    },
    // 角色列表
    getRoleLIst(param) {
        return http.get("/role/list", param)
    },
    // 添加角色
    roleCreate(param) {
        return http.post("/role/create", param)
    },
    // 删除角色
    roleDel(param, id) {
        return http.delete('/role', param, id)
    },
    // 编辑角色
    modifyRole(id, param) {
        return http.put('/role/' + id, param)
    },
    // 获取权限树
    resourceList(param) {
        return http.get("/resource_menu/list", param)
    },
    // 绑定权限
    getRoleResource(param) {
        return http.post("/role/permission_to_role", param)
    },
    // 获取用户已绑定的权限 
    roleBindingResource(param) {
        return http.get("/resource_menu/getResourceMenuTreeWithSelectedByRole", param)
    },
    // 登录时获取用户信息
    getUserInfo(param) {
        return http.get('/user/getUserinfo', param)
    },
    // 修改个人密码/user/modifyPassword
    modifyPassword(param) {
        return http.post('/user/changePassword', param)
    },
    selfChange(param) {
        return http.put('/user/self', param)
    },
    // 修改头像 /user/modifyAvatar
    modifyAvatar(param) {
        return http.post('/user/modifyAvatar', param)
    },
}
