/*
 * @Description: 品类 - apis
 * @Author: kecraft
 * @Date: 2024-01-17 14:38:17
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 09:28:01
 * @FilePath: /impact-iotos-console/src/api/modules/category.js
 */
import http from "../http"
export default {
  /**
   * 获取所有默认品类
   * @param {*} param 
   * @returns 
   */
  getDefaultCategory(param) {
    return http.get('/admin/category/default', param)
  },
  /**
   * 根据cid获取品类
   * @param {*} cid 
   * @returns 
   */
  getCategoryByCid(cid,param) {
    return http.get(`/admin/category/${cid}/simple`,param)
  },
  /**
   * 添加品类节点
   * @param {*} param 
   * @returns 
   */
  addCategory(param) {
    return http.post("/admin/category", param)
  },
  /**
   * 删除品类节点
   * @param {*} cid 
   * @returns 
   */
  delCategory(cid) {
    return http.delete(`/admin/category/${cid}`)
  },
  /**
   * 编辑品类节点
   * @param {*} cid 
   * @param {*} param 
   * @returns 
   */
  editCategory(cid, param) {
    return http.put(`/admin/category/${cid}`, param);
  },
  /**
   * 根据cid获取品类的model
   * @param {*} cid 
   * @returns 
   */
  getCategoryModelByCid(cid) {
    return http.get(`/admin/category/${cid}/model`);
  },
  /**
   * 根据cid修改品类的model
   * @param {*} cid 
   * @param {*} param 
   * @returns 
   */
  editCategoryModelByCid(cid, param) {
    return http.post(`/admin/category/${cid}/model`, param);
  },
  /**
   * 根据根节点导出品类
   * @param {*} param 
   * @returns 
   */
  exprotCategory(param) {
    return http.post("/admin/category/export", param)
  },
  /**
   * 导入品类
   * @param {*} param 
   * @returns 
   */
  improtCategory(param) {
    return http.upLoadFile("/admin/category/import", param)
  },
}