export default {
    // 批量导入
    batchFile(body, param) {
        return http.upLoadFile("/xxxxxx", body, param)
    },
    //文件上传 callback 上传进度
    fileStorage(param, callback) {
        return http.upLoadFile('/xxxx', param, callback)
    },
    //导出
    fileDownload(param) {
        return http.downLoadBlob("/examination/export/situation", param)
    },

    // 下载文件 callback 下载进度
    downFileLoadBlob(param, callback) {
        return http.downLoadBlobCallback('/file-storage/download', param, callback)
    },

}