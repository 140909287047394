/*
 * @Description: 
 * @Author: kecraft
 * @Date: 2023-07-24 09:22:14
 * @LastEditors: kecraft
 * @LastEditTime: 2024-05-29 10:45:13
 * @FilePath: /impact-iotos-console/src/utils/config.js
 */

export const javaEndPoint = process.env.VUE_APP_BASE_URL
export const javaWs = process.env.VUE_APP_WSURL

// export const javaEndPoint = 'http://10.0.1.2:8001'//庞/
// export const javaEndPoint = 'http://10.0.1.224:10104'  // 胡
// export const javaEndPoint = 'http://10.0.1.34:10104'  // 本地


// export const javaEndPoint = `http://10.0.1.48:10104` // sagiller
// export const javaWs = "ws://10.0.1.34:12348"


// export const javaWs = "ws://10.0.1.48:12345"  // sagiller
// export const javaWs = "ws://10.0.1.48:12345"  // sagiller

// export const javaWs = "ws://10.0.1.224:12345"

// 测试
// export const javaEndPoint = `http://112.124.27.147:10104` // 测试
// export const javaWs = "ws://112.124.27.147:12345"

// chouxu
// export const javaEndPoint = `http://www.zjqycx.com:3800/api` // 测试
// export const javaWs = "ws://10.10.49.179:3805"
